//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SETTLEMENT_ETA_EDIT_LINK,
  SETTLEMENT_CHECKLISTS,
  LABEL_FOR_SETTLEMENT_AGENT,
  LABEL_FOR_ASSIGNED_AT,
  LABEL_SETTLEMENT_ETA,
  LABEL_FOR_SETTLEMENT_CHECKLIST,
  LABEL_FOR_CHECKLIST_COMPLETED,
  LABEL_FOR_SETTLEMENT_CHECKLIST_NOT_COMPLETED,
  LABEL_MARK_AS_REVIEWED,
  LABEL_UNMARK_AS_REVIEWED,
  LABEL_FOR_SETTLEMENT_NOTES,
  LABEL_NOT_AVAILABLE,
  LABEL_UPDATE,
  LABEL_FOR_SUBMIT_SETTLEMENT,
  LABEL_APPLICATION_SETTLED_AT,
  LABEL_MARK_APPLICATION_AS_SETTLED,
  LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
  LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE,
  LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
  LABEL_REVIEW_AND_SEND_PROPOSAL,
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_SETTLEMENT,
  LABEL_CANCEL,
  LABEL_YES_PROCEED,
  INFO_TITLE_FOR_SETTLEMENT,
  INFO_DESCRIPTION_FOR_SETTLEMENT,
  INFO_DESCRIPTION_FOR_SETTLEMENT_COMMERCIAL,
  ERROR_MESSAGE_FOR_NOTES_MAXIMUM_LENGTH,
  LABEL_FOR_STATUS_UPDATE,
  LABEL_GO_TO_DOCUMENTS,
  MAINTAB_DOCS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
  REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
  MAINTAB_SETTLEMENT_KEY,
  SETTLEMENT_MENU_DOCUMENT_KEY,
  LABEL_PAYSLIP_MISSING_DOCUMENT,
  LABEL_GO_TO_SETTLEMENT_DOCUMENT,
  DEFAULT_DOCUMENT_TYPES,
  SAVE_AND_SEND_LABEL,
  SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL,
  LABEL_FOR_VIEW,
  SUCCESS_TITLE_FOR_CREDIT_PROPSAL,
  XERO_INVOICE_STATUS,
  INFO_TITLE_FOR_ACCOUNT_INVOICE,
  INFO_TITLE_FOR_INSURANCE_INVOICE,
  LABEL_FOR_RETRY,
  XERO_INVOICE_TYPE_INSURANCE,
  XERO_INVOICE_TPYE_WARRANTY,
  INFO_TITLE_FOR_WARRANTY_INVOICE,
  XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST,
  XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST_REGEX,
  XERO_INVOICE_ERROR_LENDER_DOES_NOT_EXIST,
  XERO_INVOICE_ERROR_EMPTY_FEES,
  GET_XERO_INVOICE_ERROR_BANNER_MESSAGE,
  XERO_INVOICE_TPYE_ACCOUNTS,
} from '~/constants/applications'
import {
  APPLICATION_STATUS_PRE_SETTLEMENT,
  APPLICATION_STATUS_SETTLED,
  LABEL_FOR_LAST_UPDATED_AT,
  CUSTOM_SETTLEMENT_APPLICATION_STATUSES,
  LABEL_FOR_DATE,
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  USER_SESSION_DATA_KEY,
  APPLICATION_REQUEST_TYPE_COMMERCIAL,
} from '@/constants'
import { format } from 'date-fns'
import { axiosErrorMessageExtractor } from '~/utils/error'
import SettlementSubTab from './SettlementSubTab.vue'
import { getSignedURL } from '~/utils/url'
import MissingFieldsModal from './UtilComponents/MissingFieldsModal.vue'
import MissingDocumntDialog from './MissingDocumentDialog.vue'
import { validateUserRoles } from '~/helpers/user'
export default {
  components: {
    SettlementSubTab,
    MissingFieldsModal,
    MissingDocumntDialog,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_FOR_SETTLEMENT_AGENT,
      LABEL_FOR_ASSIGNED_AT,
      LABEL_SETTLEMENT_ETA,
      LABEL_FOR_SETTLEMENT_CHECKLIST,
      LABEL_FOR_CHECKLIST_COMPLETED,
      SETTLEMENT_ETA_EDIT_LINK,
      SETTLEMENT_CHECKLISTS,
      LABEL_FOR_SETTLEMENT_CHECKLIST_NOT_COMPLETED,
      LABEL_MARK_AS_REVIEWED,
      LABEL_UNMARK_AS_REVIEWED,
      LABEL_FOR_SETTLEMENT_NOTES,
      LABEL_FOR_LAST_UPDATED_AT,
      LABEL_FOR_SUBMIT_SETTLEMENT,
      LABEL_APPLICATION_SETTLED_AT,
      LABEL_NOT_AVAILABLE,
      LABEL_MARK_APPLICATION_AS_SETTLED,
      LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST,
      LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE,
      LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS,
      SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL,
      LABEL_REVIEW_AND_SEND_PROPOSAL,
      LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_SETTLEMENT,
      LABEL_CANCEL,
      LABEL_YES_PROCEED,
      LABEL_UPDATE,
      LABEL_FOR_DATE,
      LABEL_FOR_STATUS_UPDATE,
      LABEL_GO_TO_DOCUMENTS,
      LABEL_GO_TO_SETTLEMENT_DOCUMENT,
      LABEL_FOR_VIEW,
      MAINTAB_DOCS_KEY,
      SAVE_AND_SEND_LABEL,
      SUCCESS_TITLE_FOR_CREDIT_PROPSAL,
      FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
      REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
      INFO_TITLE_FOR_SETTLEMENT,
      INFO_DESCRIPTION_FOR_SETTLEMENT,
      INFO_DESCRIPTION_FOR_SETTLEMENT_COMMERCIAL,
      APPLICATION_STATUS_PRE_SETTLEMENT,
      loanData: { ...this.sharedData },
      APPLICATION_STATUS_SETTLED,
      MAINTAB_SETTLEMENT_KEY,
      SETTLEMENT_MENU_DOCUMENT_KEY,
      LABEL_PAYSLIP_MISSING_DOCUMENT,
      DEFAULT_DOCUMENT_TYPES,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
      USER_SESSION_DATA_KEY,
      XERO_INVOICE_STATUS,
      INFO_TITLE_FOR_ACCOUNT_INVOICE,
      INFO_TITLE_FOR_INSURANCE_INVOICE,
      INFO_TITLE_FOR_WARRANTY_INVOICE,
      LABEL_FOR_RETRY,
      XERO_INVOICE_TYPE_INSURANCE,
      XERO_INVOICE_TPYE_WARRANTY,
      XERO_INVOICE_TPYE_ACCOUNTS,
      GET_XERO_INVOICE_ERROR_BANNER_MESSAGE,
      format,
      settlementUser: '',
      assignedToSettlementUser: '',
      settlementETA: '',
      settlementChecklist: {},
      isDialogLoading: false,
      settlementNotesUpdatedAt: '',
      submitSettlementBtnLoading: false,
      errorsdata: '',
      openMissingFieldsModal: false,
      isSettlementDailogVisible: false,
      CUSTOM_SETTLEMENT_APPLICATION_STATUSES,
      settlementStatus: '',
      isOpenSettlementETADialog: false,
      ShouldDisplayMissingDocumentDialog: false,
      errMessage: '',
      cpdUrl: null,
      openSendProposalConfirmation: false,
      isSettlement: false,
      isPreSettlement: false,
      isPreSettlementUpdate: false,
      settlementETAData: {
        settlementETA: '',
      },
      ruleForm: {
        settlementNotes: '',
      },
      rules: {
        settlementNotes: [
          {
            max: 600,
            message: ERROR_MESSAGE_FOR_NOTES_MAXIMUM_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
      },
      xeroInvoiceNumber: '',
      insuranceXeroInvoiceNumber: '',
      warrantyXeroInvoiceNumber: '',
    }
  },
  computed: {
    shouldDisableNotes() {
      return !this.isAdmin
        ? !this.isPreSettlementStatus
        : this.isSettled
        ? !this.isSettled
        : !this.isPreSettlementStatus
    },
    shouldDisplaySuccessCpdAlert() {
      return (
        !this.shouldDisplayCpdAlert &&
        this.loanData?.appData?.applicationType !==
          APPLICATION_REQUEST_TYPE_COMMERCIAL &&
        this.loanData?.appData?.loanDetails?.settlement?.preSettledAt &&
        this.loanData?.appData?.loanDetails?.creditProposalDisclosureSentAt &&
        this.loanData?.appData?.loanDetails?.creditProposalDisclosureSentAt >
          this.loanData?.appData?.loanDetails?.settlement?.preSettledAt
      )
    },
    isAdmin() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes
      return validateUserRoles(userRoles, [
        USER_TYPE_ADMIN,
        USER_TYPE_SUPER_ADMIN,
      ])
    },
    hasSettlementChecklistChecked() {
      return this.settlementChecklist && this.settlementChecklist.length
        ? this.settlementChecklist.some((x) => !x.reviewedAt)
        : true
    },
    settlementChecklistCheckedCount() {
      return this.settlementChecklist && this.settlementChecklist.length
        ? this.settlementChecklist.filter((x) => x.reviewedAt).length
        : 0
    },
    isSettled() {
      return this.loanData.appData?.status === APPLICATION_STATUS_SETTLED
    },
    SettlementForm() {
      return this.setSettlementFormData()
    },
    getBusinessName() {
      return (
        this.loanData.appData?.loanDetails?.lenderData?.businessName ||
        LABEL_NOT_AVAILABLE
      )
    },
    isPreSettlementStatus() {
      return this.loanData.appData?.status === APPLICATION_STATUS_PRE_SETTLEMENT
    },
    shouldDisabledSubmitSettleButton() {
      return !this.isPreSettlementStatus || this.shouldDisplayCpdAlert
    },
    shouldShowEditSettlementETA() {
      return (
        this.settlementETA &&
        (this.isPreSettlementStatus || (this.isAdmin && this.isSettled))
      )
    },
    proposalCreated() {
      let finalURL = null

      if (this.loanData?.appData?.sharedDocuments) {
        const document = this.loanData.appData.sharedDocuments.find(
          (docs) =>
            docs.purpose.toLowerCase() ===
            LABEL_CREDIT_PROPOSAL_DISCLOSURE.toLowerCase()
        )
        if (document) {
          finalURL = document.url
        }
      }

      return !!finalURL
    },
    shouldDisplayCpdAlert() {
      return (
        this.isPreSettlementStatus &&
        this.proposalCreated &&
        !this.isCommercial &&
        this.loanData.appData?.loanDetails?.settlement
          ?.shouldResendCpdOnSettlement
      )
    },
    shouldDisplaySettlementBanner() {
      return (
        this.loanData.appData?.loanDetails?.settlement?.preSettledAt &&
        (this.loanData.appData?.loanDetails?.creditProposalDisclosureSentAt ||
          this.isCommercial) &&
        this.loanData.appData?.loanDetails?.settlement?.settledAt
      )
    },
    xeroInvoiceBannerType() {
      if (this.loanData.appData?.tpInvoiceId) {
        return XERO_INVOICE_STATUS.SUCCESS
      } else if (
        !this.loanData.appData?.tpInvoiceId &&
        this.loanData.appData?.tpInvoiceCreationAttemptedAt
      ) {
        if (
          this.loanData.appData?.tpInvoiceErrorReason.includes(
            XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST
          )
        ) {
          const invoiceNumber =
            this.loanData.appData?.tpInvoiceErrorReason.match(
              XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST_REGEX
            )[1]
          this.setXeroInvoiceNumber(invoiceNumber)
          return XERO_INVOICE_STATUS.INVOICE_ALREADY_EXIST
        }
        if (
          this.loanData.appData?.tpInvoiceErrorReason.includes(
            XERO_INVOICE_ERROR_LENDER_DOES_NOT_EXIST
          )
        ) {
          return XERO_INVOICE_STATUS.LENDER_DOES_NOT_EXIST
        }
        if (
          this.loanData.appData?.tpInvoiceErrorReason.includes(
            XERO_INVOICE_ERROR_EMPTY_FEES
          )
        ) {
          return XERO_INVOICE_STATUS.EMPTY_FEES
        }
        return XERO_INVOICE_STATUS.ERROR_UNKNOWN
      } else {
        return XERO_INVOICE_STATUS.NOT_AVAILABLE
      }
    },
    insuranceXeroInvoiceBannerType() {
      if (this.loanData.appData?.tpInsuranceInvoiceId) {
        return XERO_INVOICE_STATUS.SUCCESS
      } else if (
        !this.loanData.appData?.tpInsuranceInvoiceId &&
        this.loanData.appData?.tpInsuranceInvoiceCreationAttemptedAt
      ) {
        if (
          this.loanData.appData?.tpInsuranceInvoiceErrorReason?.includes(
            XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST
          )
        ) {
          const invoiceNumber =
            this.loanData.appData?.tpInsuranceInvoiceErrorReason.match(
              XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST_REGEX
            )[1]
          this.setXeroInvoiceNumber(invoiceNumber, XERO_INVOICE_TYPE_INSURANCE)
          return XERO_INVOICE_STATUS.INVOICE_ALREADY_EXIST
        }
        if (
          this.loanData.appData?.tpInsuranceInvoiceErrorReason?.includes(
            XERO_INVOICE_ERROR_LENDER_DOES_NOT_EXIST
          )
        ) {
          return XERO_INVOICE_STATUS.LENDER_DOES_NOT_EXIST
        }
        if (
          this.loanData.appData?.loanDetails?.settlement?.insurance
            ?.commision == 0
        ) {
          return XERO_INVOICE_STATUS.NOT_CREATED
        }
      } else if (
        !this.loanData.appData?.tpInsuranceInvoiceId &&
        !this.loanData.appData?.tpInsuranceInvoiceCreationAttemptedAt
      ) {
        return this.loanData.appData.loanDetails.settlement.insurance.provider
          ? XERO_INVOICE_STATUS.NOT_FOUND
          : XERO_INVOICE_STATUS.NOT_AVAILABLE
      }
      return XERO_INVOICE_STATUS.ERROR_UNKNOWN
    },
    warrantyXeroInvoiceBannerType() {
      if (this.loanData.appData?.tpWarrantyInvoiceId) {
        return XERO_INVOICE_STATUS.SUCCESS
      } else if (
        !this.loanData.appData?.tpWarrantyInvoiceId &&
        this.loanData.appData?.tpWarrantyInvoiceCreationAttemptedAt
      ) {
        if (
          this.loanData.appData?.tpWarrantyInvoiceErrorReason?.includes(
            XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST
          )
        ) {
          const invoiceNumber =
            this.loanData.appData?.tpWarrantyInvoiceErrorReason.match(
              XERO_INVOICE_ERROR_INVOICE_ALREADY_EXIST_REGEX
            )[1]
          this.setXeroInvoiceNumber(invoiceNumber, XERO_INVOICE_TPYE_WARRANTY)
          return XERO_INVOICE_STATUS.INVOICE_ALREADY_EXIST
        }
        if (
          this.loanData.appData?.tpWarrantyInvoiceErrorReason?.includes(
            XERO_INVOICE_ERROR_LENDER_DOES_NOT_EXIST
          )
        ) {
          return XERO_INVOICE_STATUS.LENDER_DOES_NOT_EXIST
        }
        if (
          this.loanData.appData?.loanDetails?.settlement?.warranty?.commision ==
          0
        ) {
          return XERO_INVOICE_STATUS.NOT_CREATED
        }
      } else if (
        !this.loanData.appData?.tpWarrantyInvoiceId &&
        !this.loanData.appData?.tpWarrantyInvoiceCreationAttemptedAt
      ) {
        return this.loanData.appData.loanDetails.settlement.warranty.provider
          ? XERO_INVOICE_STATUS.NOT_FOUND
          : XERO_INVOICE_STATUS.NOT_AVAILABLE
      }

      return XERO_INVOICE_STATUS.ERROR_UNKNOWN
    },
  },
  mounted() {
    this.$nuxt.$on('reset-settlement-data', (err) => {
      this.getSettlementData()
    })
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })

    this.getSettlementData()
    this.$nuxt.$on('close-send-proposal-dialog-pre-settlement-update', () => {
      this.isPreSettlementUpdate = false
      this.openSendProposalConfirmation = false
      this.cpdUrl = null
    })
    this.$nuxt.$on('show-settlement-missing-document', (errorMsg) => {
      this.openMissingDocumentDialog(errorMsg)
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('reset-settlement-data')
    this.$nuxt.$off('close-loader')
  },
  methods: {
    viewAssetment(type) {
      const returnURL = this.loanData.appData.sharedDocuments?.find(
        (document) => document.purpose.toLowerCase() === type.toLowerCase()
      )?.url

      if (returnURL) window.open(getSignedURL(returnURL, this.$auth))
    },
    openMissingFieldsModalSettlement(err) {
      this.errorsdata = err
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    closeMissingFieldsModalSettlement() {
      this.openMissingFieldsModal = !this.openMissingFieldsModal
    },
    setSettlementFormData() {
      this.loanData = {
        appData: { ...this.sharedData },
      }
      return true
    },
    async getSettlementData() {
      const appData = this.loanData?.appData
      if (appData) {
        this.setChecklistData()
        this.ruleForm.settlementNotes = appData.settlementNotes || ''
        this.settlementNotesUpdatedAt = appData.settlementNotesUpdatedAt
        this.settlementETA = appData.loanDetails?.approval?.settlementEta
        this.assignedToSettlementUser = appData.assignedToSettlementUserAt
        this.settlementStatus =
          appData.loanDetails?.settlement?.settlementStatus
        const Id = appData.settlementUser
        if (Id) {
          await this.$axios.get(`/user/${Id}`).then((response) => {
            if (response?.data) {
              const data = response.data
              this.settlementUser = data.firstName + ' ' + data.lastName
            }
          })
        }
      }
    },
    setChecklistData() {
      const appData = this.loanData?.appData
      if (appData) {
        const savedChecklistData = appData.settlementChecklist
        const checklist = SETTLEMENT_CHECKLISTS
        this.settlementChecklist = []
        checklist.map((x) => {
          const obj = {}
          const data = savedChecklistData?.find((y) => y.name === x.key)
          if (data) {
            obj.name = x.key
            obj.label = x.label
            obj.reviewedAt = data.reviewedAt
            obj.reviewedBy = data.reviewedBy
            obj.details = x.details
          } else {
            obj.name = x.key
            obj.label = x.label
            obj.reviewedAt = ''
            obj.reviewedBy = ''
            obj.details = x.details
          }
          this.settlementChecklist.push(obj)
        })
      }
    },
    async updateSettlementChecklistItem(data, token) {
      try {
        this.isDialogLoading = true
        const { id, applicationId } = this.$route.params
        let data1 = [...this.settlementChecklist]
        data1 = data1.map((x) => {
          if (x.name === data) {
            x.reviewedAt = token ? new Date().toISOString() : ''
          }
          return x
        })
        const payload = {
          settlementChecklist: data1,
        }
        const response = await this.$store.dispatch(
          'applications/updateSettlementChecklistItem',
          {
            id,
            applicationId,
            payload,
          }
        )
        if (response?.status === 200) {
          this.setChecklistData()
        }
      } catch (err) {
        console.log('Error:-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
    async saveChecklistNotes() {
      try {
        let isValid = false
        this.$refs.ruleForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isDialogLoading = true
        const { id, applicationId } = this.$route.params
        const loanDetails = { ...this.loanData.appData }
        loanDetails.settlementNotes = this.ruleForm.settlementNotes
        loanDetails.settlementNotesUpdatedAt = new Date().toISOString()
        await this.$store.dispatch('applications/saveChecklistNotes', {
          loanDetails,
          id,
          applicationId,
        })
        this.settlementNotesUpdatedAt = new Date().toISOString()
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
    async submitSettlement() {
      this.submitSettlementBtnLoading = true
      const { id, applicationId } = this.$route.params

      const status = APPLICATION_STATUS_SETTLED

      try {
        await this.$store.dispatch('applications/checkMissingFields', {
          loanDetails: { ...this.loanData.appData },
          id,
          applicationId,
          status,
        })
        this.isSettlementDailogVisible = true
      } catch (err) {
        if (
          axiosErrorMessageExtractor(err).match(
            this.REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
          )
        ) {
          this.getMissingDocuments(err)
          this.isDialogLoading = false
        } else if (err?.response?.data?.errors) {
          const error = err?.response?.data?.errors
          this.errorsdata = error
          this.openMissingFieldsModal = true
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
        this.isDialogLoading = false
      }

      this.submitSettlementBtnLoading = false
    },
    async settleApplication() {
      try {
        const { id, applicationId } = this.$route.params
        this.isDialogLoading = true
        const loanDetails = { ...this.loanData.appData }
        loanDetails.settlementNotes = this.ruleForm.settlementNotes
        await this.$store.dispatch('applications/settlementApplication', {
          loanDetails,
          id,
          applicationId,
          settled: true,
        })
        this.getSettlementData()
        if (
          this.loanData?.appData?.tpInvoiceErrorReason === 'Invalid lender info'
        ) {
          setTimeout(() => {
            this.$notify({
              title: 'Error',
              message: `Unable to create the accounts invoice as lender account ${
                this.getBusinessName != LABEL_NOT_AVAILABLE
                  ? this.getBusinessName
                  : '' || ''
              } not found. Please check with support and try again.`,
              type: 'error',
              duration: 5000,
            })
          }, 2000)
        } else if (
          this.loanData?.appData?.tpInvoiceErrorReason?.includes(
            'Duplicate Invoice #,'
          )
        ) {
          const invoiceNumber =
            this.loanData.appData?.tpInvoiceErrorReason.match(
              /Duplicate Invoice #, ([\w-]+)/
            )[1]
          setTimeout(() => {
            this.$notify({
              title: 'Error',
              message: `Invoice ${invoiceNumber} already exists. Please check with support for more details.`,
              type: 'error',
              duration: 5000,
            })
          }, 2000)
        } else if (
          this.loanData?.appData?.tpInvoiceErrorReason?.includes(
            'Both lending commission and origination fee are 0'
          )
        ) {
          setTimeout(() => {
            this.$notify({
              title: 'Error',
              message: `Accounts invoice is not created because both the broker fee and lending commission are zero. Please contact support for any further inquiry.`,
              type: 'error',
              duration: 5000,
            })
          }, 2000)
        } else if (this.loanData?.appData?.tpInvoiceErrorReason) {
          setTimeout(() => {
            this.$notify({
              title: 'Error',
              message:
                'Unable to create the accounts invoice. Please check with support and try again.',
              type: 'error',
              duration: 5000,
            })
          }, 2000)
        } else if (this.loanData?.appData?.tpInvoiceId) {
          setTimeout(() => {
            this.$notify.success({
              title: 'Success',
              message: 'Invoice created Successfully.',
            })
          }, 2000)
        }
      } catch (err) {
        console.error('error', err)
        if (
          axiosErrorMessageExtractor(err).match(
            this.REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
          )
        ) {
          this.getMissingDocuments(err)
          this.isDialogLoading = false
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      } finally {
        this.isDialogLoading = false
        this.isSettlementDailogVisible = false
      }
    },
    getMissingDocuments(errMessage) {
      this.errMessage = errMessage
      this.ShouldDisplayMissingDocumentDialog = true
    },
    closeMissingDocDialog() {
      this.ShouldDisplayMissingDocumentDialog = false
    },
    async handleChange(event) {
      try {
        if (this.settlementStatus) {
          this.isDialogLoading = true
          const { id, applicationId } = this.$route.params
          const response = await this.$store.dispatch(
            'applications/saveSettlementSatus',
            {
              event,
              id,
              applicationId,
            }
          )
          if (response?.data) {
            this.settlementStatus = event
          }
        } else {
          return
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
    openCloseSettlementETADialog() {
      this.settlementETAData.settlementETA = this.settlementETA
      this.isOpenSettlementETADialog = !this.isOpenSettlementETADialog
    },
    async updateSettlementETA() {
      try {
        let isValid = false
        this.$refs.settlementETAform.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isDialogLoading = true
        const { id, applicationId } = this.$route.params
        const updatedSettlementETA = this.settlementETAData?.settlementETA
          ? new Date(this.settlementETAData?.settlementETA).toISOString()
          : ''
        const response = await this.$store.dispatch(
          'applications/setSettlementEta',
          {
            settlementEtaDate: updatedSettlementETA,
            id,
            applicationId,
          }
        )
        if (response?.data) {
          this.settlementETA = this.settlementETAData.settlementETA
          this.openCloseSettlementETADialog()
        }
      } catch (err) {
        console.log('Error:-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
    openMissingDocumentDialog(errorMsg) {
      this.openSendProposalConfirmation = false
      this.cpdUrl = null
      this.ShouldDisplayMissingDocumentDialog = true
      this.errMessage = errorMsg
    },
    async reviewAndSendProposal(type) {
      try {
        this.isPreSettlement = false
        this.isSettlement = false
        this.isPreSettlementUpdate = true
        this.cpdUrl = null
        let returnURL = null
        const document = await this.loanData.appData.sharedDocuments.filter(
          (docs) => docs.purpose.toLowerCase() === type.toLowerCase()
        )
        if (document) {
          returnURL = document[0].url
        }
        this.cpdUrl = await getSignedURL(returnURL, this.$auth)
        setTimeout(() => {
          this.openSendProposalConfirmation = true
        }, 1000)
      } catch (err) {
        console.log('Error:-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
    async handleRetryCreateInvoice(type = '') {
      const { id, applicationId } = this.$route.params
      const businessName = this.getBusinessName

      await this.$store.dispatch('applications/createXeroInvoice', {
        id,
        applicationId,
        lenderBusinessName:
          businessName != LABEL_NOT_AVAILABLE ? businessName : '',
        type,
      })
    },
    setXeroInvoiceNumber(xeroInvoiceNumber, type = '') {
      switch (type) {
        case XERO_INVOICE_TPYE_WARRANTY:
          this.warrantyXeroInvoiceNumber = xeroInvoiceNumber
          break
        case XERO_INVOICE_TYPE_INSURANCE: {
          this.insuranceXeroInvoiceNumber = xeroInvoiceNumber
          break
        }
        default:
          this.xeroInvoiceNumber = xeroInvoiceNumber
          break
      }
    },
  },
}
