//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import MessageCode from '@/components/MessageCode.vue'
import { mobileWithCountryCode } from '../helpers/mobile'
import { axiosErrorMessageExtractor } from '../utils/error'
import { isMobile } from '../helpers/validators'
import {
  ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
  USER_SESSION_DATA_KEY,
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_REFERRER,
  LOGIN_TITLE,
  LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE,
} from '~/constants'
import appTitleMixin from '~/mixins/appTitle.mixin'
import { changeRoute } from '~/helpers/changeRoute'
import { datadogRum } from '@datadog/browser-rum'
import { validateUserRoles } from '~/helpers/user'
import {
  TOO_MANY_OTP_REQUESTS_ERROR_MESSAGE,
  TOO_MANY_REQUEST_FROM_SAME_IP_ERROR_MESSAGE,
} from '~/constants/errors'

export default {
  components: { MessageCode },
  middleware: 'auth',
  mixins: [appTitleMixin],
  data() {
    return {
      LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE,
      isRequestCodeLoading: false,
      isSubmitLoginLoading: false,
      codeRequested: false,
      loginData: {
        mobile: '',
        oneTimePassword: '',
      },
    }
  },
  computed: {
    headConfig() {
      return {
        title: LOGIN_TITLE,
      }
    },
    rules() {
      return {
        mobile: [
          {
            required: true,
            message: 'Please input mobile number',
            trigger: ['blur', 'change'],
          },
          { validator: isMobile, trigger: ['blur', 'change'] },
        ],
        oneTimePassword: [
          {
            required: true,
            message: 'Please input SMS code',
            trigger: 'change',
          },
        ],
      }
    },
  },
  async created() {
    if (localStorage.getItem('accelerate-login-email')) {
      localStorage.removeItem('accelerate-login-email')
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: '/auth/email-login',
      })
    }
  },
  mounted() {
    const { token, refreshToken } = this.$route.query
    if (token && refreshToken) {
      this.impersonateUserLogin(token, refreshToken)
    }

    if (this.$auth.loggedIn) {
      if (this.$auth.$storage.getUniversal('redirect')) {
        this.$router.replace(this.$auth.$storage.getUniversal('redirect'))
        this.$auth.$storage.removeUniversal('redirect')
        return
      }
      this.$router.replace('/')
    }
  },
  methods: {
    handleInput(event) {
      if (isNaN(event)) {
        return
      }
      this.loginData.mobile = event
    },
    async authenticateNumber() {
      try {
        this.isRequestCodeLoading = true
        // For some reason, the promised version of `validateField` does not
        // return anything, and the callback version doesn't return a boolean.
        // Instead, it returns the error string from validation.
        // Who the hell designed this API!?!?!?
        const isValid = await new Promise((resolve) =>
          this.$refs.loginForm.validateField('mobile', (result) =>
            // Negate the value because the presence of something means
            // validation failed (because it's the error string if the
            // validation fails)
            resolve(!result)
          )
        )
        if (!isValid) {
          return
        }
        await this.$axios.post('/user/authenticate-number', {
          mobile: mobileWithCountryCode(this.loginData.mobile),
        })
        this.codeRequested = true
        this.$notify({
          title: 'Success',
          message: 'Successfully sent SMS code',
          type: 'success',
          duration: 3000,
        })
        Vue.nextTick(() => this.$refs.otpField.onFocus())
      } catch (err) {
        console.error(err)
        const showErrorMessage = axiosErrorMessageExtractor(err).includes(
          TOO_MANY_REQUEST_FROM_SAME_IP_ERROR_MESSAGE
        )
          ? TOO_MANY_OTP_REQUESTS_ERROR_MESSAGE
          : axiosErrorMessageExtractor(err)
        this.$notify({
          title: 'Error',
          message: showErrorMessage,
          type: 'error',
          duration: 3000,
        })
      } finally {
        this.isRequestCodeLoading = false
      }
    },
    async userLogin() {
      this.isSubmitLoginLoading = true
      try {
        const isValid = await this.$refs.loginForm.validate()
        if (!isValid) {
          return
        }
        const data = {
          mobile: mobileWithCountryCode(this.loginData.mobile),
          oneTimePassword: this.loginData.oneTimePassword,
        }
        const response = await this.$auth.loginWith('accelerate', {
          data,
        })

        const { user } = response.data
        this.$auth.setUser(user)
        this.$auth.$storage.setUniversal(USER_SESSION_DATA_KEY, user)
        if (
          !validateUserRoles(user.systemRoleTypes, [
            USER_TYPE_ADMIN,
            USER_TYPE_SUPER_ADMIN,
            USER_TYPE_REFERRER,
          ])
        ) {
          this.$notify({
            title: 'Error',
            message: ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
            type: 'error',
            duration: 3000,
          })
          // this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
          this.$auth.setUser(null)
          await this.$auth.logout()

          this.loginData.mobile = ''
          this.loginData.oneTimePassword = ''
          this.codeRequested = false
          return
        }

        datadogRum.setUser({
          id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          mobile: user.mobile,
          role: user.type,
        })

        if (response?.data) {
          await changeRoute({
            route: this.$route,
            router: this.$router,
            path: '/',
          })
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 3000,
        })

        this.loginData.oneTimePassword = ''
        // Need to defer the focus update slightly, otherwise it just doesn't
        // straight up work, probably due to a race condition of some sort.
        Vue.nextTick(() => this.$refs.otpField.onFocus())
      } finally {
        this.isSubmitLoginLoading = false
      }
    },

    async impersonateUserLogin(token, refreshToken) {
      this.isSubmitLoginLoading = true
      this.$auth.strategy.token.set(token)
      this.$auth.strategy.refreshToken.set(refreshToken)
      try {
        const response = await this.$axios.get('/user/me', {
          headers: {
            'x-access-token': token,
          },
        })

        const { data } = response
        this.$auth.setUser(data)
        this.$auth.$storage.setUniversal(USER_SESSION_DATA_KEY, data)

        if (
          !validateUserRoles(data.systemRoleTypes, [
            USER_TYPE_ADMIN,
            USER_TYPE_SUPER_ADMIN,
            USER_TYPE_REFERRER,
          ])
        ) {
          this.$notify({
            title: 'Error',
            message: ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
            type: 'error',
            duration: 3000,
          })
          // this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
          this.$auth.setUser(null)
          await this.$auth.logout()
          return
        }
        if (data) {
          datadogRum.setUser({
            id: data?._id,
            name: `${data?.firstName} ${data?.lastName}`,
            email: data?.email,
            mobile: data?.mobile,
            role: data?.type,
          })
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 3000,
        })

        this.loginData.oneTimePassword = ''
        // Need to defer the focus update slightly, otherwise it just doesn't
        // straight up work, probably due to a race condition of some sort.
        Vue.nextTick(() => this.$refs.otpField.onFocus())
      } finally {
        this.isSubmitLoginLoading = false
      }
    },
  },
}
